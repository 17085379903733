<template>
	<v-container>
		<Progress :error="error" :loading="loading" type="overlay"></Progress>

		<v-flex xs12>
			<channel-list :items="items" type="set"></channel-list>
		</v-flex>
		<v-flex xs12 class="text-md-center">
			<scroll-pagination v-if="hasMorePage" :page="page" :loading="loading" @page="onScroll"></scroll-pagination>
		</v-flex>
	</v-container>
</template>

<script>
import ChannelList from "../components/channel-list.vue";
import ScrollPagination from "../components/helper/scroll-pagination.vue";

export default {
	components: { ChannelList, ScrollPagination },
	data() {
		return {
			loading: false,
			items: null,
			error: null,
			page: 1,
			hasMorePage: false
		};
	},
	created() {
		var t = this;
		document.title = 'Подборки';
		t.fetchData();
	},
	methods: {
		fetchData() {
			var t = this;
			t.error = null;
			t.loading = true;

			var body = JSON.stringify({page: t.page});

			window.ajax("/api/set/list", { body: body }, function(
				data,
				error
			) {
				t.loading = false;
				if (!error) {
					t.hasMorePage = data.meta.hasMore;
					if (t.page == 1) {
						t.items = data.data;
					} else {
						t.items = [...t.items, ...data.data];
					}
				} else {
					t.error = data;
				}
			});
		},
		onScroll(page) {
			this.page = page;
			this.fetchData();
		},
	},
};
</script>
<style scoped>
</style>